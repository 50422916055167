.searchresults-drop {
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1), -5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.searchresults-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
