.Assorter-4x8-17,
.CarryLite55-Boxlayout1,
.Assorter-4x8-16 {
  display: grid;
  height: 100%;
  width: 100%;
  row-gap: 5px;
  column-gap: 10px;
}

.Assorter-4x8-17,
.Assorter-4x8-16 {
  grid-template-rows: repeat(4, 25%);
  grid-template-columns: repeat(8, 1fr);
}

.CarryLite55-Boxlayout1 {
  grid-template-rows: repeat(5, 20%);
  grid-template-columns: repeat(10, 1fr);
}

.preview-grid-cell {
  background-color: white;
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.2), -2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #292e2b;
  outline: 0 none;
  padding: 7px 0px;
}

.searchresults-grid-cell {
  background-color: white;
  border-width: 1px;
  box-sizing: content-box;
  border-color: rgb(112, 110, 110);
  border-radius: 4px;
  color: #292e2b;
  outline: 0 none;
}

.storage-box-grid-wrapper {
  aspect-ratio: 1.414;
  width: 125px;
}

.box-grid-wrapper {
  aspect-ratio: 1.414;
  max-height: 900px;
  width: auto;
}

@media (min-width: 2000px) {
  .box-grid-wrapper {
    max-height: 1200px;
  }
}

@media (max-width: 950px) {
  .box-grid-wrapper {
    max-height: 400px;
  }
}
