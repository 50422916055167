.add-button-container {
  position: absolute;
  left: 2rem;
  top: 2rem;
}

.add-box-popup {
  width: 25vw;
  height: auto;
  position: absolute;
  z-index: 200;
  left: 40px;
  top: 35px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2), -2px -2px 3px 0px rgba(0, 0, 0, 0.2),
    -2px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

.box-template-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.cell-add-button {
  position: absolute;
  z-index: 200;
  right: 0.5rem;
  top: 2rem;
}

.hardcode-grid {
  display: grid;
  width: 95%;
  height: 70vh;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 5px;
  column-gap: 10px;
}

.hardcode-grid > .cell:hover {
  cursor: pointer;
}

.right-sidebar {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  box-shadow: -3px 0px 7px 0px rgba(0, 0, 0, 0.1), 5px 0px 7px 0px rgba(0, 0, 0, 0.1);
  padding: 4px;
}

.cell {
  background-color: white;
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.2), -2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #292e2b;
  outline: 0 none;
  position: relative;
  transition: all 0.3s ease-in-out 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 7px 0px;
}

.cell::after,
.cell::before {
  border-radius: 4px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out 0s;
  width: 100%;
  z-index: 3;
}

.cell::before {
  border-bottom: 4px solid #737474;
  border-left: 4px solid #737474;
  transform-origin: 0 100% 0;
}

.cell::after {
  border-right: 4px solid #737474;
  border-top: 4px solid #737474;
  transform-origin: 100% 0 0;
}

.cell:hover::after,
.cell:hover::before {
  transform: scale(1);
}

.cellclicked {
  background-color: white;
  box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.25), -2px 0px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 7px 0px;
  color: #292e2b;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.cellclicked::after,
.cellclicked::before {
  border-radius: 4px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1);
  transition: all 0.3s ease-in-out 0s;
  width: 100%;
  z-index: 3;
}

.cellclicked::before {
  border-bottom: 4px solid var(--chakra-colors-indigo-700);
  border-left: 4px solid var(--chakra-colors-indigo-700);
}

.cellclicked::after {
  border-right: 4px solid var(--chakra-colors-indigo-700);
  border-top: 4px solid var(--chakra-colors-indigo-700);
}

.cell-number {
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 25, 94, 0.3);
  margin-bottom: 3px;
}

.cell-content-entry {
  box-shadow: 3px 0px 2px 0px rgba(0, 0, 0, 0.3);
}

.cellContent-artnr-entry,
.cellContent-qty-entry {
  overflow-wrap: break-word;
  white-space: normal;
}

.cell-content-table-container {
  width: 95%;
  max-height: 70%;
  z-index: 4;
}

.cell-content-table {
  height: 100%;
}

.table-head {
  background-color: #414957;
  z-index: 200;
}

.cell-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 70%;
  position: relative;
}

.delete-cell-item-btn {
  position: absolute;
  top: -35px;
  right: 3px;
  z-index: 200;
}

.delete-cell-table-entry-btn {
  position: absolute;
  right: 0px;
  top: 10px;
  right: 3px;
}

.delete-cell-content-entry-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--wt-color-secondary-accent);
  outline: var(--wt-outline-default);
  outline-offset: var(--wt-outline-offset);
}

.cell-body,
.viewer-cell-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.viewer-cell-body {
  justify-content: flex-start;
}

.cell-body {
  justify-content: space-evenly;
}

.cell-viewer-quantity-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cell-body {
  height: 35vh;
}

.viewer-card {
  height: 65vh;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  box-shadow: -3px 0px 7px 0px rgba(0, 0, 0, 0.1), 3px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.cell-content-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: green;
  flex-direction: column;
}

.cell-content-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 4px;
}

.cell-img,
.cell-editor-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.editor-card {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  box-shadow: -3px 0px 7px 0px rgba(0, 0, 0, 0.1), 3px 0px 7px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.editor-card-show-btn {
  position: absolute;
  bottom: 0px;
  left: -40%;
}

.editor-search-container {
  width: 100%;
  height: 50px;
}

.card-image-container {
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
}

.edit-cell-content-container,
.view-cell-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 9px;
}

.product-list {
  height: 37vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-list-item:hover {
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15), -5px -2px 7px 0px rgba(0, 0, 0, 0.15);
}

.product-list-item {
  transition: box-shadow 0.2s ease-in-out;
}

.edit-cell-content-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
}

.edit-cell-input {
  height: auto;
}

.content-pane {
  display: grid;
  position: relative;
  grid-template-columns: 3.7fr 1fr;
}

.active-cell-img-div {
  height: 50%;
  width: 90%;
  background-color: blue;
}

.list-item {
  cursor: pointer;
}

.invalid-qty {
  position: absolute;
  bottom: -20px;
  color: white;
  background-color: black;
}

.item-search-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exists-checkmark {
  position: absolute;
  left: 5px;
  font-size: 1.5rem;
}

#empty-cell-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.product-viewer-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-template-item {
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
}

.box-template-item:hover {
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.box-template-selector-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finish-box-button {
  position: absolute;
  right: 50%;
  top: 2rem;
}

.cancel-box-button {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.grid-animation-enter-active {
  opacity: 1;
  transform: translateY(0%) scale(1);
  transition: all 1000ms ease-in-out;
}

/* starting EXIT animation */
.grid-animation-exit {
  opacity: 1;
  transform: scale(1.01);
}

/* ending EXIT animation */
.grid-animation-exit-active {
  opacity: 0;
  transform: scale(4);
  transition: all 1000ms ease-in-out;
}

.edit-label-input-container {
  position: absolute;
  top: 2rem;
  left: 38%;
}
